const { TR_LOCALE, EN_LOCALE } = require("./lib/locales");
const { i18n } = require("./next-i18next.config");
const { siteInfo } = require("./siteInfo");


const generateLocaleSource = (locale, path) => {
  return i18n.defaultLocale !== locale ? "/" + locale + path : path;
};

const trRoutes = {
  "/": {
    config: {
      rewrite: {
        source: generateLocaleSource(TR_LOCALE.hl, "/"),
        destination: "/",
        locale: TR_LOCALE.hl,
      },
      redirect: {},
    },
    meta: {
      title: "Appizsoft | Yazılım & Tasarım & Dijital Pazarlama",
      description: "Dijital ve yazılım çözümleri ortağınız olarak, işletmenize özel stratejiler ve yazılım hizmetleri sunarak dijital ve yazılım alanlarında rekabet avantajı elde etmenizi sağlıyoruz.",
      canonical: siteInfo + generateLocaleSource(TR_LOCALE.hl, "/"),
    },
    sitemap: {
      pageName: "pages-sitemap.xml",
      changeFreq: "weekly",
      priority: "1",
    },
  },
  "/products": {
    config: {
      rewrite: {
        source: generateLocaleSource(TR_LOCALE.hl, "/urunler"),
        destination: "/products",
        locale: TR_LOCALE.hl,
      },
      redirect: {},
    },
    meta: {
      title: "Ürünler  | AppizSoft",
      description: "En son teknolojilerle geliştirilmiş yazılım ürünlerimizi inceleyin ve ihtiyaçlarınıza özel çözümleri keşfedin.",
      canonical: siteInfo + generateLocaleSource(TR_LOCALE.hl, "/urunler"),
    },
    sitemap: {
      pageName: "pages-sitemap.xml",
      changeFreq: "weekly",
      priority: "1",
    },
  },
};

const enRoutes = {
  "/": {
    config: {
      rewrite: {
        source: generateLocaleSource(EN_LOCALE.hl, "/"),
        destination: "/",
        locale: EN_LOCALE.hl,
      },
      redirect: {},
    },
    meta: {
      title: "Appizsoft | Software & Design & Digital Marketing",
      description: "As your digital and software solutions partner, we enable you to gain a competitive advantage in digital and software fields by offering strategies and software services specific to your business.",
      canonical: "https://example.com/en",
    },
    sitemap: {
      pageName: "pages-sitemap.xml",
      changeFreq: "weekly",
      priority: "1",
    },
  },
  "/products": {
    config: {
      rewrite: {
        source: generateLocaleSource(EN_LOCALE.hl, "/products"),
        destination: "/products",
        locale: EN_LOCALE.hl,
      },
      redirect: {},
    },
    meta: {
      title: "Products | AppizSoft",
      description: "Check out our software products developed with the latest technologies and discover solutions specific to your needs.",
      canonical: "https://example.com/en",
    },
    sitemap: {
      pageName: "pages-sitemap.xml",
      changeFreq: "weekly",
      priority: "1",
    },
  },
};

module.exports = {
  tr: trRoutes,
  en: enRoutes,
};
